import Messages from '@core/scripts/components/messages';
import Topics from '@core/scripts/components/topics';
import History from '@core/scripts/components/history';
import MessageBroker from '@bonprix/pattern-library/components/message-broker';
import JshModule from '@core/scripts/helper/jsh-module';

const {moduleScope} = JshModule('header/my-account');

const tooltipLabelNode = moduleScope.querySelector('[jsh-my-account__label]');
const tooltipCloserNode = moduleScope.querySelector('[jsh-my-account__closer]');
const tooltipContentNode = moduleScope.querySelector('[jsh-my-account__flyout]');
moduleScope.classList.add('is-initialized');
let opened = false;
let closeTooltipWhenOutsideMessageIdentifier;

setDataContentHeight();

tooltipLabelNode.addEventListener('click', toggleTooltip);
tooltipCloserNode.addEventListener('click', (event) => {
    History.remove('my-account');
    closeTooltip(event);
});
MessageBroker.subscribe(`${Topics.HISTORY}.my-account`, closeTooltip);
MessageBroker.subscribe(Messages.NAVIGATION_ACCORDION.OPENED, () => {
    History.remove('my-account');
    closeTooltip();
});

function toggleTooltip(event) {
    event.preventDefault();
    if (!opened) {
        History.push('my-account');
        openTooltip();
    } else {
        if (!tooltipContentNode.contains(event.target)) {
            History.remove('my-account');
            closeTooltip(event);
        }
    }
}

function openTooltip() {
    tooltipContentNode.style.maxHeight = tooltipContentNode.data.contentHeight;
    tooltipLabelNode.classList.add('is-opened');
    opened = true;
    closeTooltipWhenOutsideMessageIdentifier = MessageBroker.subscribe(
        Messages.WINDOW.CLICKED,
        closeTooltipWhenOutside
    );
}

function closeTooltip(event) {
    if (event) {
        event.preventDefault();
    }
    tooltipContentNode.style.maxHeight = '';
    tooltipLabelNode.classList.remove('is-opened');
    opened = false;
    MessageBroker.unsubscribe(closeTooltipWhenOutsideMessageIdentifier);
    if (event) {
        event.stopPropagation();
    }
}

// Close tooltip when the click event happened outside the tooltip
function closeTooltipWhenOutside(event) {
    if (!moduleNode.contains(event.target)) {
        event.preventDefault();
        History.remove('my-account');
        closeTooltip(event);
    }
}

function setDataContentHeight() {
    tooltipContentNode.data = {
        contentHeight: `${tooltipContentNode.querySelector('[jsh-my-account__toggle-height]').offsetHeight}px`,
    };
}
